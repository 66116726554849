<template>
  <div
    :class="{ inline }"
    :style="style"
    class="DataFilter"
  >
    <DateRange
      ref="datePicker"
      :no-label="noDateLabel"
      :default-range="filters.tasksFilters.dateRange"
      @apply="applyFilter('tasksFilters', 'dateRange', $event)"
    />
    <div
      v-if="displayFilters"
      class="filter-wrapper"
    >
      <span
        v-if="!noFilterLabel"
        class="filter-label"
      >
        Filter By:
      </span>
      <div
        :style="styleFilters"
        class="filter-buttons"
      >
        <FilterButton
          v-if="!noTagFilter"
          :items="tags.all()"
          :default-items="defaultFilters.tagsFilters ? defaultFilters.tagsFilters.uuids : []"
          name="Tag"
          icon="el-icon-news"
          @apply="applyFilter('tagsFilters', 'uuids', $event)"
        />
        <FilterButton
          v-if="!noProjectFilter"
          :items="projects.all()"
          :default-items="defaultFilters.projectsFilters ? defaultFilters.projectsFilters.uuids : []"
          name="Project"
          icon="el-icon-document"
          @apply="applyFilter('projectsFilters', 'uuids', $event)"
        />
        <FilterButton
          v-if="!noTeamFilter"
          :items="teams.all()"
          :default-items="defaultFilters.teamsFilters ? defaultFilters.teamsFilters.uuids : []"
          name="Team"
          placeholder="Find teams..."
          icon="el-icon-star-off"
          no-empty
          @apply="applyFilter('teamsFilters', 'uuids', $event)"
        />
        <FilterButton
          v-if="!noMemberFilter"
          :items="organizationMembers.all()"
          name="Member"
          icon="el-icon-user"
          no-empty
          @apply="applyFilter('usersFilters', 'uuids', $event)"
        />
        <FilterButton
          v-if="!noDescriptionFilter"
          text
          name="Description"
          icon="el-icon-star-off"
          no-empty
          @apply="applyFilter('tasksFilters', 'description', $event)"
        />
      </div>
    </div>
    <GroupBy
      v-if="!noGroupingOptions"
      :grouping-options="groupingOptions"
      :default-group-by="defaultFilters.groupBy"
      @apply="applyFilter('groupBy', null, $event)"
    />
    <ExportOptions
      v-if="showExportOptions"
      @export="exportTo"
    />
  </div>
</template>

<script>
/* eslint-disable prefer-template */
import moment from 'moment';
import { mapGetters } from 'vuex';
import { UserRepository, TeamRepository, ProjectRepository, TagRepository } from '@timeragent/core';

export default {
  name: 'DataFilter',

  components: {
    DateRange: () => import('./data-filter/DateRange.vue'),
    FilterButton: () => import('./data-filter/FilterButton.vue'),
    GroupBy: () => import('./data-filter/GroupBy.vue'),
    ExportOptions: () => import('./data-filter/ExportOptions.vue'),
  },

  props: {
    groupingOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    showExportOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    noFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    noGroupingOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    noDateLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    noFilterLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    noTagFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    noProjectFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    noTeamFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    noMemberFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    noDescriptionFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultFilters: {
      type: Object,
      required: false,
      default: () => ({
        groupBy: 'task',
        tasksFilters: {
          dateRange: [
            moment().startOf('day').toDate(),
            moment().endOf('day').toDate(),
          ],
        },
      }),
    },
  },

  data() {
    return {
      filters: {
        ...this.defaultFilters,
      },
      locUser: {},
      userRepository: new UserRepository(),
      teamRepository: new TeamRepository(),
      projectRepository: new ProjectRepository(),
      tagRepository: new TagRepository(),
    };
  },

  computed: {
    ...mapGetters([
      'user',
      'date',
    ]),
    teams() {
      return this.teamRepository.dataset;
    },
    projects() {
      return this.projectRepository.dataset;
    },
    organizationMembers() {
      return this.userRepository.dataset;
    },
    tags() {
      return this.tagRepository.dataset;
    },
    displayFilters() {
      return !this.noFilters && this.filtersCount;
    },
    style() {
      let columns = 1;

      if (this.showExportOptions) columns += 1;
      if (!this.noFilters) columns += 1;
      if (!this.noGroupingOptions) columns += 1;

      return {
        'grid-template-columns': `repeat(${columns}, max-content)`,
      };
    },
    filtersCount() {
      let columns = 0;

      if (!this.noTagFilter) columns += 1;
      if (!this.noProjectFilter) columns += 1;
      if (!this.noTeamFilter) columns += 1;
      if (!this.noMemberFilter) columns += 1;
      if (!this.noDescriptionFilter) columns += 1;

      return columns;
    },
    styleFilters() {
      return {
        'grid-template-columns': `repeat(${this.filtersCount}, max-content)`,
      };
    },
  },

  watch: {
    // Watch date in the vuex store
    date(value) {
      // if date in the store was set to today date, reset datepicker to defaults (today date)
      if (value.getTime() === moment().startOf('day').toDate().getTime()) {
        this.$refs.datePicker.dateRange = this.defaultFilters.tasksFilters.dateRange;
        this.$refs.datePicker.applyFilter(this.defaultFilters.tasksFilters.dateRange);
      }
    },
  },

  async created() {
    if (!this.user.uuid) {
      this.locUser = await this.userRepository.one();
    }
    await this.projectRepository.many({
      filters: {
        ...this.defaultFilters,
        organizationUuid: this.organizationUuid,
      },
      withTasks: false,
    });
    await this.teamRepository.many({
      filters: {
        ...this.defaultFilters,
        organizationUuid: this.organizationUuid,
      },
    });
    await this.userRepository.many({
      ...this.defaultFilters,
      organizationUuid: this.organizationUuid,
    });
    await this.tagRepository.many();

    this.$emit('apply', this.defaultFilters);
  },

  methods: {
    applyFilter(type, field, data) {
      let filter = data;

      if (field) {
        filter = {
          [field]: data,
        };
      }

      if (this.filters[type] && field) {
        this.$set(this.filters, type, {
          ...this.filters[type],
          ...filter,
        });
      } else {
        this.$set(this.filters, type, filter);
      }

      // Sometimes GraphQL converts '' to null, so let's remove it at all
      if (type === 'description' && data === '') {
        delete this.filters.description;
      }

      this.$emit('apply', this.filters);
    },
    exportTo(format) {
      this.$emit('export', format);
    },
  },
};
</script>

<style
  scoped
  lang="stylus"
>
  .DataFilter
    display grid
    grid-template-columns repeat(5, max-content)
    grid-gap 25px

    &.inline
      display inline-grid

    .filter-wrapper
      display grid
      grid-template-columns max-content min-content
      grid-gap 10px
      align-items center

    .filter-buttons
      display grid
      grid-template-columns repeat(4, max-content)
      grid-gap 15px
</style>
